<template>
  <div>
    <ml-container>
      <ml-grid>
        <div class="tw-mt-12 tw-text-center tw-text-black tw-col-start-2 tw-col-span-6">
          <page-title>Legal</page-title>
          <div class="tw-flex tw-space-x-12 tw-justify-center tw-mb-8">
            <a href="#" class="tw-text-gray-n4 tw-bg-white tw-shadow-sm tw-border tw-border-gray-n0 tw-rounded-lg tw-w-60 tw-px-4 tw-py-6 tw-flex tw-items-center">
              <div class="tw-h-8 tw-w-8 tw-bg-gray-n0 tw-rounded-full tw-flex tw-items-center tw-justify-center tw-mr-3">
                <link-icon class="tw-h-3 tw-w-3" />
              </div>
              <router-link :to="{ name: 'privacy.policy' }" >
              Privacy Policy
              </router-link>
            </a>
            <a href="#" class="tw-text-gray-n4 tw-bg-white tw-shadow-sm tw-border tw-border-gray-n0 tw-rounded-lg tw-w-60 tw-px-4 tw-py-6 tw-flex tw-items-center">
              <div class="tw-h-8 tw-w-8 tw-bg-gray-n0 tw-rounded-full tw-flex tw-items-center tw-justify-center tw-mr-3">
                <link-icon class="tw-h-3 tw-w-3" />
              </div>
              <router-link :to="{ name: 'terms.and.conditions' }" >
              Terms of Use
              </router-link>
            </a>

          </div>
          <p class="tw-mb-3 tw-leading-relaxed">
            For legal inquiries, please contact
          </p>
          <a href="mailto:legal@mangolola.com" class="tw-text-red tw-leading-relaxed">legal@mangolola.com</a>
        </div>
      </ml-grid>
    </ml-container>
  </div>
</template>

<script>
import i18n from '@/i18n'
import { LinkIcon } from 'vue-feather-icons'
import MlContainer from "@/components/layout/MlContainer";
import PageTitle from "@/components/layout/titles/PageTitle";
import MlGrid from "@/components/layout/MlGrid";

export default {
  components: {
    MlGrid,
    PageTitle,
    MlContainer,
    LinkIcon,
  },
  layout: 'basic',
  metaInfo() {
    return {title: i18n.t('legal')}
  },
}
</script>
